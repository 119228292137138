import { defineStore } from 'pinia';
// import { computed, ref } from 'vue';
import {
  fetchSuggestionAi,
  fetchGoogleSuggestionAi,
  updateUserPreferenceToHideIaAlertMessage,
  fetchGoogleReviewResponseTemplateSuggestionAi,
} from '@services/reviewService';

export const useReviewsStore = defineStore({
  id: 'reviews',
  state: () => ({
    iaSuggestions: [], // [{ reviewId: 1, suggestion: "..." }]
    reviewResponses: [], // [{ reviewId: 1, message: "...", responseTemplateName: "..." }]
    surveyReviewResponses: [], // [{ surveyReviewId: 1, message: "...", responseTemplateName: "..." }]
    googleIaSuggestions: [],
    googleReviewResponses: [],
    showIaAlertMessage: [],
  }),
  getters: {
    getIaSuggestionById: state => {
      return reviewId => {
        const iaSuggestion = state.iaSuggestions.find(iaSuggestion => iaSuggestion.reviewId === reviewId);
        return (iaSuggestion && iaSuggestion.suggestion) || null;
      };
    },
    getReviewResponseById: state => {
      return reviewId => {
        return state.reviewResponses.find(reviewResponse => reviewResponse.reviewId === reviewId);
      };
    },
    getSurveyReviewResponseById: state => {
      return surveyReviewId => {
        return state.surveyReviewResponses.find(surveyReviewResponse => surveyReviewResponse.surveyReviewId === surveyReviewId);
      };
    },
    getGoogleIaSuggestionById: state => {
      return googleReviewId => {
        const googleIaSuggestion = state.googleIaSuggestions.find(googleIaSuggestion => googleIaSuggestion.googleReviewId === googleReviewId);
        return (googleIaSuggestion && googleIaSuggestion.suggestion) || null;
      };
    },
    getGoogleReviewResponseById: state => {
      return googleReviewId => {
        return state.googleReviewResponses.find(googleReviewResponse => googleReviewResponse.googleReviewId === googleReviewId);
      };
    },
    getGoogleIaSuggestionBySentiment: state => {
      return ratingSentiment => {
        const googleIaSuggestion = state.googleReviewResponses.find(googleIaSuggestion => googleIaSuggestion.ratingSentiment === ratingSentiment);
        return (googleIaSuggestion && googleIaSuggestion.suggestion) || null;
      };
    },
  },
  actions: {
    async fetchAndStoreSuggestionIa({ reviewId }) {
      const res = await fetchSuggestionAi({ reviewId });
      if (res.data.error) {
        return { error: res.data.error, suggestion: '' };
      } else {
        const newSuggestion = res.data.text;
        this.updateSuggestionIaById({ reviewId, suggestion: newSuggestion });
        return { error: null, suggestion: newSuggestion };
      }
    },
    async fetchAndStoreGoogleSuggestionIa({ googleReviewId, clinicId }) {
      const res = await fetchGoogleSuggestionAi({ googleReviewId, clinicId });
      if (res.data.error) {
        return { error: res.data.error, suggestion: '' };
      } else {
        const newSuggestion = res.data.text;
        this.updateGoogleSuggestionIaById({ googleReviewId, suggestion: newSuggestion });
        return { error: null, suggestion: newSuggestion };
      }
    },
    async fetchAndStoreGoogleSuggestionTemplateIa({ ratingSentiment, clinicId }) {
      const res = await fetchGoogleReviewResponseTemplateSuggestionAi({ clinicId, ratingSentiment });
      if (res.data.error) {
        return { error: res.data.error, suggestion: '' };
      } else {
        const newSuggestion = res.data.text;
        this.updateGoogleReviewResponseTemplateWithSentiment({ ratingSentiment, suggestion: newSuggestion });
        return { error: null, suggestion: newSuggestion };
      }
    },
    hideIaAlertMessage() {
      this.showIaAlertMessage = false;
      updateUserPreferenceToHideIaAlertMessage();
    },
    updateSuggestionIaById({ reviewId, suggestion }) {
      const existingSuggestionIndex = this.iaSuggestions.findIndex(item => item.reviewId === reviewId);
      if (existingSuggestionIndex !== -1) {
        this.iaSuggestions.splice(existingSuggestionIndex, 1, { reviewId, suggestion });
      } else {
        this.iaSuggestions.push({ reviewId, suggestion });
      }
    },
    updateReviewResponseById({ reviewId, message, responseTemplateName }) {
      const existingReviewReponseIndex = this.reviewResponses.findIndex(item => item.reviewId === reviewId);
      if (existingReviewReponseIndex !== -1) {
        this.reviewResponses.splice(existingReviewReponseIndex, 1, { reviewId, message, responseTemplateName });
      } else {
        this.reviewResponses.push({ reviewId, message, responseTemplateName });
      }
    },
    updateSurveyReviewResponseById({ surveyReviewId, message, responseTemplateName }) {
      const existingReviewReponseIndex = this.surveyReviewResponses.findIndex(item => item.surveyReviewId === surveyReviewId);
      if (existingReviewReponseIndex !== -1) {
        this.surveyReviewResponses.splice(existingReviewReponseIndex, 1, { surveyReviewId, message, responseTemplateName });
      } else {
        this.surveyReviewResponses.push({ surveyReviewId, message, responseTemplateName });
      }
    },
    updateGoogleSuggestionIaById({ googleReviewId, suggestion }) {
      const existingSuggestionIndex = this.googleIaSuggestions.findIndex(item => item.googleReviewId === googleReviewId);
      if (existingSuggestionIndex !== -1) {
        this.googleIaSuggestions.splice(existingSuggestionIndex, 1, { googleReviewId, suggestion });
      } else {
        this.googleIaSuggestions.push({ googleReviewId, suggestion });
      }
    },
    updateGoogleReviewResponseById({ googleReviewId, message, responseTemplateName }) {
      const existingReviewReponseIndex = this.googleReviewResponses.findIndex(item => item.googleReviewId === googleReviewId);
      if (existingReviewReponseIndex !== -1) {
        this.googleReviewResponses.splice(existingReviewReponseIndex, 1, { googleReviewId, message, responseTemplateName });
      } else {
        this.googleReviewResponses.push({ googleReviewId, message, responseTemplateName });
      }
    },
    updateGoogleReviewResponseTemplateWithSentiment({ ratingSentiment, suggestion }) {
      const existingReviewReponseIndex = this.googleReviewResponses.findIndex(item => item.ratingSentiment === ratingSentiment);
      if (existingReviewReponseIndex !== -1) {
        this.googleReviewResponses.splice(existingReviewReponseIndex, 1, { ratingSentiment, suggestion });
      } else {
        this.googleReviewResponses.push({ ratingSentiment, suggestion });
      }
    },
  },
});
